import React from "react";
import { Helmet } from 'react-helmet'

import {SmallHero, Header, Footer} from "../fragments";
import Divider from "../components/Divider";

const ConfidentialPolicy = () => {
  return (
    <main className="overflow-hidden">
      <Helmet>
        <title>Wisoftify - Politique de confidentialité</title>
      </Helmet>
      <Header />
      <SmallHero title="Politique de confidentialité" />
      <div className="bg-gray-100 pt-8 pb-6" style={{minHeight: "100vh"}}>
      <h2 className="text-center">Mentions légales</h2>
        <p className="mx-auto text-center" style={{width: "80%"}}>
        <br /><strong>Données personnelles</strong> :<br />
        De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet wisoftify.fr.<br />
        Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ».<br />
        Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d’information.<br />
        Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d’une simple navigation sur notre site internet, notamment : des informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d’accès.<br />
        De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.<br />
        </p>
      </div>
      <Footer />
    </main>
  )
}

export default ConfidentialPolicy;